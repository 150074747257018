require('core-js');require('regenerator-runtime/runtime');// webpack
import $ from "jquery";
window.jQuery = $;
window.$ = $;
import { TweenMax } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
require("../../../wdh-foundation/dgs/dgs-api.js");
require("../../../wdh-foundation/dgs/dgs-events.js");
require("../../../wdh-foundation/dgs-utils/dgs-utils.js");
require("@demant/megamenu");
require("@demant/burgermenu");
require("jquery-bridget");
require("masonry-layout");
require("jquery-nice-select/js/jquery.nice-select.min.js");
require("jquery-ui-dist/jquery-ui.min.js");
require("iframe-resizer");
require("@demant/legacy/googlemaps");
require("./header.js");
require("./feature/popup.js");
require("./feature/component-center-locator.js");
require("./feature/component-country-selector.js");
require("./feature/text-image-spot.js");
require("./feature/component-intro-banner.js");
require("./feature/component-extranet.js");
require("./feature/component-resource-center.js");
require("./feature/component-flexspot-pardot-form");
require("./feature/component-flexspot-slider.js");
require("./feature/component-flexspot-video.js");
require("./feature/component-form.js");
require("./feature/component-geo-ip-prompt.js");
require("../../corporate-template/js/feature/video-spot.js");
require("../../corporate-template/js/feature/cookies.js");
require("../../corporate-template/js/feature/form.js");
require("../../corporate-template/js/feature/iframe.js");
require("../../corporate-template/js/feature/image-spot.js");
require("../../corporate-template/js/feature/intro-banner.js");
require("../../corporate-template/js/feature/rich-text.js");
require("../../corporate-template/js/feature/search-base.js");
require("../../corporate-template/js/feature/search.js");
require("../../corporate-template/js/feature/submenu-point-spot.js");
require("../../corporate-template/js/feature/two-section-grid.js");
require("../../corporate-template/js/project/main.js");
require("@demant/wdh-gdpr/lib/default.implementation.js");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-a-data-hierarchy");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-b-data-hierarchy-appframe-component");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-b-data-hierarchy-hubspot");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-b-data-hierarchy-multi-step");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-b-data-hierarchy-resource-center");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-data-hierarchy-dataset");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-data-hierarchy-spot-ui-elements");
require("./feature/component-data-hierarchy-spot-hideEmptyFilters");