(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var equalWidth = function equalWidth() {
      var $groupList = $('.country-selector-item-wrapper'),
        i,
        $hlist,
        maxWidth;
      $groupList.each(function (event) {
        var $hList = $('li > :not(a)', $(this));
        $hList.width('auto');
        maxWidth = -1;
        $hList.each(function (event) {
          maxWidth = Math.max(Math.ceil(this.getBoundingClientRect().width), maxWidth);
        });
        $hList.width(maxWidth);
      });
    };
    window.DGS.Resizer.getInstance().register(equalWidth);
  });
})(jQuery);