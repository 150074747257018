require('../../../corporate-template/js/feature/intro-banner');
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.intro-banner').each(function (index, banner) {
      var introBannerLink = $(banner).find('.intro-banner-link');
      if (introBannerLink && introBannerLink.find('a').length > 0) {
        $(introBannerLink).addClass('full-active-screen');
        $(introBannerLink).appendTo($(banner));
      }
    });
  });
})(jQuery);