(function ($) {
  'use strict';

  window.DGS.OnLoad.getInstance().register(function () {
    $('.component.submenu-point-spot').each(function () {
      var $component = $(this);
      $('a', $component).each(function () {
        var target = $(this),
          targetName = target.attr('name');
        if (targetName !== undefined) {
          $('a[href=#' + targetName + ']').each(function () {
            $(this).on('click', function (e) {
              var targetPosition = target.position(),
                top = targetPosition ? targetPosition.top : null,
                submenuHeight = $component.length ? $component.outerHeight() : 0;
              e.preventDefault();
              $('html, body').animate({
                'scrollTop': top - submenuHeight
              }, 600);
            });
          });
        }
      });
    });
  });
})(jQuery, window.DGS);