(function ($, DGS) {
  "use strict";

  var locatorLinks = ["/locator"];
  var contactLinks = ["/about-us/contact"];
  var burgerMenuConfig = {
      expanderSelector: ".header-mobile-top .nav-expander",
      menuItemsClass: "text-left",
      groupTwoLastLevels: false,
      zIndex: 3
    },
    megaMenuConfig = {
      hover: true,
      secondLevelNav: true,
      displayTypeBg: "flex"
    };
  DGS.OnLoad.getInstance().register(function () {
    var sectionShown,
      $logo = $("#header .image"),
      $link = $("#header .link"),
      $menu = $("#header .navigation"),
      $search = $("#header .search-field-spot"),
      $mainContainer = $("#wrapper > #header"),
      $languageSelector = $(".language-selector-link"),
      headerSettings = {
        mainContainer: $mainContainer,
        determineSectionCallback: function determineSectionCallback(section) {
          sectionShown = section == DGS.TABLET_LAYOUT ? DGS.MOBILE_LAYOUT : section;
          return sectionShown;
        },
        sections: {
          mobile: {
            containers: [{
              className: "header-mobile-top",
              components: [$logo, $('<div class="nav-expander"><span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span></div>')]
            }, {
              className: "header-mobile-menu",
              components: [$menu]
            }]
          },
          tablet: {},
          desktop: {
            containers: [{
              className: "header-desktop-top",
              containers: [{
                className: "row-1",
                components: [$logo, $search, $link]
              }, {
                className: "row-2",
                components: [$menu]
              }]
            }]
          }
        }
      };

    // BUILD MARKUP
    DGS.Builder.build(headerSettings);
    var on = DGS.Event.getInstance().on(DGS.Events.BUILDER_DONE, function (settings) {
      if (settings !== headerSettings) return; // ONLY RUN POST SETUP IF IT'S THE RIGHT BUILDER

      $("#header").addClass("animate");

      // add SEARCH placeholder to an input
      $("#header .search-field-spot .search-field > input").attr("placeholder", "SEARCH");

      // SETUP MEGA MENU
      $(".desktop-section .navigation .component-content > ul").megaMenu(megaMenuConfig);
      // SETUP BURGER MENU
      var backButton = '<div class="nav-back">Back</div>';
      var $social = $('<div class="component contact"></div>');
      var $navBottom = $('<div class="nav-bottom"></div>');
      $(".mobile-section .navigation .component-content > ul li").each(function () {
        var link = $(this).find("a");
        if (contactLinks.includes(link.attr("href"))) {
          var contact = {
            title: link.attr("title") || "",
            href: link.attr("href")
          };
          var $phoneLink = $("<a></a>").attr(contact).addClass("call-icon");
          var $emailLink = $("<a></a>").attr(contact).addClass("email-icon");
          var $contact = $("<p></p>").append([$phoneLink, $emailLink]).addClass("left");
          var $languageSelectorLink = $("a[href$='/language-selector']");
          $social.append($contact);
          $(".nav-bottom").append($languageSelectorLink);
        }
      });
      $(".mobile-section .navigation .component-content > ul li a").each(function () {
        var href = $(this).attr("href");
        if (locatorLinks.includes(href)) {
          var locator = {
            title: $(this).attr("title") || "",
            href: href
          };
          var $languageSelectorLink = $("a[href$='/language-selector']");
          var $locatorLink = $("<a></a>").attr(locator).html(locator.title);
          var $locatorButton = $locatorLink.wrap('<div class="component locator"></div>').parent();
          $navBottom.append($locatorButton);
          $(this).parent().remove();
        }
      });
      $navBottom.append($search);
      $navBottom.append($languageSelector);
      burgerMenuConfig.appendElements = [$navBottom];
      $(".mobile-section .navigation").before(backButton);
      $(".mobile-section .navigation .component-content > ul").burgerMenu(burgerMenuConfig);
      DGS.Event.getInstance().on(DGS.Events.BURGER_MENU_OPEN, function () {
        $("body").addClass("no-overflow");
      });
      DGS.Event.getInstance().on(DGS.Events.BURGER_MENU_CLOSE, function () {
        $("body").removeClass("no-overflow");
      });
      // REGISTER RESIZER HANDLER
      DGS.Resizer.getInstance().register(function () {
        $(".nav-bottom");
        // ADJUST CONTENT SECTION BY ADDING BOTTOM MARGIN TO HEADER
        var headerHeight = 0;
        if (sectionShown === DGS.DESKTOP_LAYOUT) {
          headerHeight = $("#header .desktop-section > .inner-container").outerHeight();
          $(".dgs-mega-menu li a[href='/select-language']").addClass("hide-on-desktop");
          $(".dgs-mega-menu li a[href='http://www.extranet.maico-diagnostics.com/']").addClass("hide-on-desktop");
        } else if (sectionShown === DGS.MOBILE_LAYOUT) {
          headerHeight = $("#header .header-mobile-top").outerHeight();
          $(".dgs-burger-menu").css("top", headerHeight);
          $(".nav-back").css("top", headerHeight);
        }
        $("#header").css("margin-bottom", headerHeight);
      });
    });

    // Prevent browser from showing tooltip with title attributes
    var $attr = $("[title]");
    $attr.hover(function () {
      var title = $(this).attr("title");
      $(this).attr("temp-title", title);
      $(this).attr("title", "");
    });
    $attr.click(function () {
      var title = $(this).attr("temp-title");
      $(this).attr("title", title);
    });
    // Prevent browser from showing tooltip with title attributes - end
  });
})(jQuery, window.DGS);