//* HTML below sholud be placed in S9 tags
{/* <div class="once-per-session popup" style="display:none;">
 <div class="popup-outer">
  <div class="popup-inner">
    <p>Maico Diagnostics（www.maico-diagnostics.com）Lorem ipsum</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
    <p>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    <p style="font-weight:bold;">Ut enim ad minim veniam,</p>
    <div class="popup-buttons">
     <a href="#" class="pop-btn no">no</a><a href="#" class="pop-btn yes">yes</a>
   </div>
  </div>
 </div>
 </div> */}
window.DGS.OnLoad.getInstance().register(function () {
  $('.once-per-session.popup').each(function () {
    var $this = $(this),
      cookie = DGS.Cookies.getInstance().readCookie("maico-medical-alert");
    if (!cookie) {
      $this.addClass('popup-show');
      $('.pop-btn.yes', $this).click(function () {
        DGS.Cookies.getInstance().createCookie("maico-medical-alert");
        $this.removeClass('popup-show');
      });
      $('.pop-btn.no', $this).click(function () {
        $('.pop-warning').removeClass('warning-hide');
        //TODO: what to do if user click no?
      });
    }
  });
});