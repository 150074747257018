(function ($, DGS) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    DGS.Components.DataHierarchySpot.getInstance().register("ui-elements", getBuildConfiguration, postBuildActions);
  });
  function getBuildConfiguration(data, $spot) {
    // build navigation
    var navigation = [];
    navigation.push(createNameLink('Colors'));
    navigation.push(createNameLink('Typography'));
    navigation.push(createNameLink('Buttons'));
    navigation.push(createNameLink('Form elements'));

    // build color entries
    var colors = [];
    if (data.hasOwnProperty('Color')) {
      data['Color'].forEach(function (colorEntry, index) {
        colors.push($('<div class="color bg-color-' + (index + 1) + ' ' + colorEntry.cssClass + (colorEntry.hexCode == '#FFFFFF' ? ' white-color-border' : '') + '"><div class="color__name">' + colorEntry.name + '</div><div class="color__hexcode">' + colorEntry.hexCode + '</div>'));
      });
    }
    var types = [];
    if (data.hasOwnProperty('Typography')) {
      data['Typography'].forEach(function (type) {
        var attr = '';
        if (type.htmlTag === 'a') {
          attr = ' href="#"';
        }
        var $type = $('<' + type.htmlTag + attr + ' class="type__example">');
        $type.text(type.text);
        $type.wrap('<div>');
        if (type.backgroundCSSClasses) {
          $type.parent().addClass(type.backgroundCSSClasses).css('padding', '1rem');
        }
        types.push($('<div class="type">').append($type.parent()).append('<div class="type__description">' + type.fontName + ' / ' + type.size + ' / ' + type.defaultColorHexCode + ' / Line height: ' + type.lineHeight + '</div>'));
      });
    }
    var fonts = [];
    if (data.hasOwnProperty('Font')) {
      data['Font'].forEach(function (font) {
        fonts.push($('<div class="font ' + font.type + '">' + font.text + '</div>'));
      });
    }
    var buttons = [];
    if (data.hasOwnProperty('Button')) {
      data['Button'].forEach(function (button) {
        var cssClass = button.hasOwnProperty('cssClass') ? button.cssClass : '';
        buttons.push($('<div>').addClass('button-element').append($('<button>').addClass(cssClass).text(button.text)));
      });
    }
    var forms = [];
    if (data.hasOwnProperty('Form')) {
      data['Form'].forEach(function (form) {
        var $elem = $(form.htmlTagName).addClass('form-element__example'),
          $label = $('<label>').addClass('form-element__label').text(form.label),
          $description = $('<div class="form-element__description">').text(form.description);
        forms.push($('<div class="form-element">').append($label).append($elem).append($description));
      });
    }
    var config = {
      mainContainer: $('.component-content', $spot),
      sections: {
        desktop: {
          containers: [{
            className: 'section-navigation',
            components: navigation
          }]
        },
        mobile: {} // Not showing anything on mobile
      }
    };
    if (colors.length) {
      config.sections.desktop.containers.push({
        className: 'section',
        components: [getContainerHeader('Colors')].concat(colors)
      });
    }
    if (fonts.length || types.length) {
      config.sections.desktop.containers.push({
        className: 'section',
        components: [getContainerHeader('Typography')].concat(fonts).concat(types)
      });
    }
    if (buttons.length) {
      config.sections.desktop.containers.push({
        className: 'section',
        components: [getContainerHeader('Buttons')].concat(buttons)
      });
    }
    if (forms.length) {
      config.sections.desktop.containers.push({
        className: 'section',
        components: [getContainerHeader('Form elements')].concat(forms)
      });
    }
    return config;
  }
  function postBuildActions($spot) {
    $('.navigation a', $spot).click(function () {
      $('.navigation a', $spot).removeClass('active');
      $(this).addClass('active');
    });
    DGS.Scroller.getInstance().register(function (scrollTop) {
      if (scrollTop > $spot.offset().top - 30) {
        $('.section-navigation', $spot).addClass('sticky');
        $('.section-navigation', $spot).css('top', $('#content').offset().top + 20);
      } else {
        $('.section-navigation', $spot).removeClass('sticky');
      }
    });
  }
  function getContainerHeader(name) {
    return $('<a name="' + name.toLowerCase().replace(' ', '') + '"></a><div class="header">' + name + '<div class="header__underline"></div></div>');
  }
  function createNameLink(name) {
    return $('<div>').addClass('navigation').append('<a href="#' + name.toLowerCase().replace(' ', '') + '">' + name + '</a>');
  }
})(jQuery, window.DGS);